.login-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-screen .amazon-button {
  margin-top: 100px;
}
