.app-error-modal {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-items: center;
  overflow: hidden;
}

.app-error-modal .box {
  background-color: white;
  margin: 0 20%;
  width: 100%;
  padding: 20px 15px 0 15px;
  display: flex;
  flex-direction: column;
}

.app-error-modal .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
