.button {
  background-color: transparent;
  border: none;
  padding: 20px;
  font-size: 16px;
  color: blue;
  display: block;
}

.button:disabled {
  color: grey;
}
