.shopping-list-screen {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.shopping-list-screen .list {
  margin: 40px 0;
}

.shopping-list-screen ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.shopping-list-screen .item {
  padding: 20px 0;
  margin: 0 50px;
  border-bottom: 1px solid #eee;
}

.shopping-list-screen .item:last-child {
  border-bottom: none;
}

.shopping-list-screen .item.completed {
  text-decoration: line-through;
}
