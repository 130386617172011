.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0px;
}

.bottom-bar ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.bottom-bar li {
  list-style: none;
  width: 100%;
}

.bottom-bar button {
  padding: 40px 10px;
  width: 100%;
  border: 0;
  background-color: #efefef;
}

.bottom-bar button.active {
  background-color: blue;
  color: white;
}
